import React from 'react';
import {
  Container,
  Table,
  ListGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import falseImg from '../assets/images/false.png';

const renderData = [
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Language For Visual Scripting</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>The visual scripting language can be used to create automated drag-and-drop items</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Fill In Forms Automatically</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Complete web-based forms automatically using either pre-defined or random data.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Manipulate Data</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>User-friendly tables, lists and variables can be used to process advanced data.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Smart Applications</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use "if-then" and conditionals for the creation of smart applications.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Search for it and Save it</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>You can search for any type of content on a web page and save it, including CSS data, Jvascript and hidden HTML.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Debug</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>BotChief's integrated debugger can easily and quckly debug your scripting.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Captcha Solving</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Integrated third party service allows for remote or manual solving of captchas. (2Captcha, DeathbyCaptcha and Others Integrated)</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },{
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Latest Anti-Fingerprint technology</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use the latest Anti-Fingerprint technology to bypass bot detection</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Web Analyzer</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Integrated web analyzer can inspect the source code of any website.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Record and Save</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Record browser activity and covert it into scripting that can be edited afterwards.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Integrated Regex Building</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Integrated Regex builder has common footprints to find addresses, emails etc. allowing you to scrape text.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Basic Set of Commands</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Create software using BotChief's basic command set.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Threads</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>How many threads you can run tasks.</p>
      </>
    ),
    pro: (
      <span
        style = {{ color: '#82BD27' }}
      >
        Muliti
      </span>
    ),
    elite: (
      <span
        style = {{ color: '#82BD27' }}
      >
        Muliti
      </span>
    ),
    dev: (
      <span
        style = {{ color: '#82BD27' }}
      >
        Muliti
      </span>
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Customize Browser Settings</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Customize browser settings fast and easily, like proxy, UserAgent, Header and directory for downloaded file settings etc.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Manage Cookie and Cache</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Easy to manage and operate cookie and cache at any time.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Emailing</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Click on links in email bodies automatically, scan them for vital data, plus send and receive.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Pro Commands</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Create advanced apps using BotChief's set of pro commands.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Random Content</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use the integrated spinner to create instant random content, or use any of the integrated popular spin APIs.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Manage Csv Files</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Manage your data easily by reading and writing text csv files.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Create Your Own Commands</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Custom commands can be created and used in all your bots.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>System Bots</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Automate your tasks out of the box with our pre-set system bots.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Fixed Run Times</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Set a time for your task to run.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },{
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#4e4eff', fontSize: 16 }}>Support Network</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Get support in easy-to-understand English from our professional team.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Support For SQL</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Access, grab and edit data from any SQL, SQLite or MySQL server database.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Elite Commands</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use elite command set to create software.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Socket Level Activity</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Direct web page interaction to avoid rendering javascript or images, or you can connect directly through HTTP.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Shell Commands</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use shell commands to open other apps quckly.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Recognize Images</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Automatic interaction with Java, Flash etc. using Image Recognition.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Automate Windows</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Automate Windows typing and clicking.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Script Compilation</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Compile bots to executable files for installation by users who don't have BotChief.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Lock Function</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Lock Function is used to lock any desired commands to avoid problems that may occur under situation of multithreading.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },{
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F148A5', fontSize: 16 }}>Create, edit, and run your bots with Chrome</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use the local Chrome browser as a bot editor, and then write, modify and test the template in it. When you run the bot in the compiler or runner, it will also open Chrome to perform operations.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Advanced Script Compilation</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Script compilation into executable files for installation by anyone, even without BotChief.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>White Label Apps</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Remove the BotChief branding from your software.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },{
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Enhanced UI Editor</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use BotChief's specialized HTML5 ui editor to create custom interfaces.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Comprehensive Install Packages</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Create comprehensive install packages that contain everything your customers need.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Auto-Update and Licensing</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use our integrated auto-update and licensing system for your software, allowing you to focus only on selling it!</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Developer Command Set</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use BotChief's set of developer commands to create your pro software.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Access Botchief Store</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Sell your product on <a href="http://store.botchief.com" target = '_blank' rel="noopener noreferrer" style = {{ color: '#2eb090' }}>store.botchief.com</a> to make money in few hours.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#F4484D', fontSize: 16 }}>Set bot's permissions</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Prevent others from editing and using your bot.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Integrate repeated operations in a table to manipulate all data with ease.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Integrate commands into the block to make your template code more concise and easier to understand.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Debug module step by step. It’s easy and fast to find any problem in your module.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Edit customized program interface by using Html page and create unique and powerful bots.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Choose different versions of browser independently and randomly to edit module.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Merge multiple tables to generate a totally new table.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use XPath to search for html elements.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Quick find command function.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Some browser commands can be converted directly.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Random Canvas can be used when running bots</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>View the values of all variables in the Variable Viewer. Can quickly create, edit variables, set variable values, create commands and use this variable.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Cut, Copy, Paste Function Command.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>More advanced functions of browser custom settings, such as custom settings of Screen Resolution.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Image recognition function enables you to find any element on web pages with ease and perform any operation to them.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Record and simulate any mouse click or keyboard input on web pages.
        </p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use mouse to move and click randomly on web pages, to easily bypass bot detecting just as a human.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Use keyboard to fill in anything on web pages, to easily bypass bot detecting just as a human</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Switch-case is used to decide multiple If-then situations at the same time and then perform different operations.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Shorten url by using bitly, tiny.cc and goo.gl services.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Get phone number and verification code by using SMSPva, GetSMSCode and other services, to solve phone verification easily.</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Drag html element creation command</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Drag the html element to create the command and customize the created command type</p>
      </>
    ),
    pro: (
      <img
        src = { falseImg }
        alt = "falseImg"
        style = {{ width: 16, height: 16 }}
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
  {
    functions: (
      <>
        <h4 style = {{ marginBottom: 0, color: '#BF9A55', fontSize: 16 }}>Much more functions...</h4>
        <p style = {{ marginBottom: 0, color: '#999', fontSize: 14 }}>Not listed here.</p>
      </>
    ),
    pro: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    elite: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    ),
    dev: (
      <FontAwesomeIcon
        icon = { faCheck }
      />
    )
  },
];

export default function Functions() {
  return (
    <section
      id = 'functions'
    >
      <Container>
        <h2>Functions</h2>
        <p>In BotChief, we have provided enough functions to meet your bot creation requirements and we have also provided some very simple operating methods, such as mouse-clicking and drag-dropping. Listed below are the main functions (there are many other functions not included in the list). And of course, we are constantly adding new functions!</p>
        <Table
          responsive
          // bordered
          hover
          id = 'functions__table'
        >
          <thead>
            <tr>
              <th>Main Functions</th>
              <th style = {{ textAlign: 'center' }}>Pro</th>
              <th style = {{ textAlign: 'center' }}>Elite</th>
              <th style = {{ textAlign: 'center' }}>Developer</th>
            </tr>
          </thead>
          <tbody>
            {
              renderData && renderData.map((item, index) => (
                <tr
                  key = { index }
                >
                  <td>{ item.functions }</td>
                  <td style = {{ textAlign: 'center', fontSize: 24, color: '#4e4eff' }}>{ item.pro }</td>
                  <td style = {{ textAlign: 'center', fontSize: 24, color: '#4e4eff' }}>{ item.elite }</td>
                  <td style = {{ textAlign: 'center', fontSize: 24, color: '#4e4eff' }}>{ item.dev }</td>

                </tr>
              ))
            }
          </tbody>
          <tfoot>
            <tr>
              <th>Main Functions</th>
              <th style = {{ textAlign: 'center' }}>Pro</th>
              <th style = {{ textAlign: 'center' }}>Elite</th>
              <th style = {{ textAlign: 'center' }}>Developer</th>
            </tr>
          </tfoot>
        </Table>

        <ListGroup
          id = 'functions__list-group'
        >
          {
            renderData && renderData.map((item, index)  => (
              <ListGroup.Item
                key = { index }
              >
                <div
                  className = 'functions__list-group__item'
                >
                  <div>{ item.functions }</div>
                  <div>
                    <div>
                      <span>Pro</span>
                      <span style = {{ color: '#4e4eff' }}>{ item.pro }</span>
                    </div>
                    <div>
                      <span>Elite</span>
                      <span style = {{ color: '#4e4eff' }}>{ item.elite }</span>
                    </div>
                    <div>
                      <span>Dev</span>
                      <span style = {{ color: '#4e4eff' }}>{ item.dev }</span>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ))
          }
        </ListGroup>
      </Container>

      <style jsx>{`
        section {
          padding: 48px 0;
          background-color: #F8F8F8;
        }
        section h2 {
          position: relative;
          padding-bottom: 12px;
          text-align: center;
          font-size: 36px;
          text-transform: uppercase;
        }
        section h2::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 10%;
          height: 2px;
          background-color: #999;
        }
        section p {
          text-align: center;
          color: rgb(153, 153, 153);
        }
      `}</style>

      <style jsx global>{`
        #functions__list-group .functions__list-group__item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        #functions__list-group .functions__list-group__item > div:first-child {
          margin-right: 20px;
          width: calc(80% - 20px);
        }
        #functions__list-group .functions__list-group__item > div:last-child {
          width: 20%;
        }
        #functions__list-group .functions__list-group__item > div:last-child > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media screen and (min-width: 768px) {
          section #functions__table {
            display: table;
          }
          section #functions__list-group {
            display: none;
          }
        }
        @media screen and (max-width: 768px) {
          section #functions__table {
            display: none;
          }
          section #functions__list-group {
            display: block;
          }
        }
      `}</style>
    </section>
  )
}