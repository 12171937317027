import React, { useState } from 'react';
import {
  Navbar,
  Nav,
  Alert,
  Container,
  Row,
  Col
} from "react-bootstrap";

export default function Header() {
  const [ isShow, setIsShow ] = useState(true);

  return (
    <header>
      <Container>
        <Navbar
          collapseOnSelect
          expand = "lg"
          variant = "light"
          // fixed = 'top'
          className = 'bg-white'
        >
          <Navbar.Brand
            href = "/"
          >
            <img
              src = {require('../assets/images/logo.png')}
              alt = "logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls = "responsive-navbar-nav"
          />
          <Navbar.Collapse
            className = "justify-content-end"
          >
            <Nav
              style = {{ textTransform: 'uppercase' }}
            >
              <Nav.Link
                active
                onClick = {() => {
                  window.$('html,body')
                    .animate({
                      scrollTop: 0
                    },450);
                }}
              >
                Introduce
              </Nav.Link>
              <Nav.Link
                href = ""
                onClick = {() => {
                  window.$('html,body')
                    .animate({
                      scrollTop: window.$('#functions').offset().top - 66
                    },450);
                }}
              >
                Functions
              </Nav.Link>
              <Nav.Link
                href = ''
                onClick = {() => {
                  window.$('html,body')
                    .animate({
                      scrollTop: window.$('#startNow').offset().top - 66
                    },450);
                }}
              >
                Start Now
              </Nav.Link>
              <Nav.Link href="http://support.botchief.com/refund_request" target = '_blank'>Refund Request</Nav.Link>
              {/* <Nav.Link href="http://reviews.botchief.com" target = '_blank'>Reviews</Nav.Link> */}
              <Nav.Link className = 'wa-ptn-nav wa-pre-hide'>Partners</Nav.Link>
            </Nav>
            <div
              id = "partners-nav"
              className = "wa-white wa-pre-hide"
            />
          </Navbar.Collapse>
        </Navbar>

        {
          isShow&& (
            <Alert
              className = 'text-center header__notice'
              dismissible
              onClose = {e => setIsShow(e)}
              style = {{ marginBottom: 0 }}
            >
              {/* <p>If you don't want to create bot by yourself, we also have the <a href="https://www.whitehatbox.com/bbs/Custom" target='_blank' rel="noopener noreferrer">Customized Development Service.</a></p> */}
              <Row>
                <Col md><li>Create customized bot/software for yourself using.</li></Col>
                <Col md><li>Sell your products to make fast money.</li></Col>
                <Col md><li>High-quality product and cheap price.</li></Col>
               </Row>
            </Alert>
          )
        }
      </Container>

      <style jsx>{`
        // #root{
        //   height: 10000px;
        // }
        @media screen and (max-width: 992px){
          header img {
            height: 80%;
          }
        }
      `}</style>

      <style jsx>{`
        header{
          position: sticky;
          left: 0;
          top: 0;
          z-index: 1030;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
          background-color: #fff;
        }
      `}</style>

      <style jsx global>{`
        header nav .navbar-nav > a {
          white-space: nowrap;
        }
        header .header__notice {
          padding-top: 0;
        }
        header .header__notice > p {
          margin-bottom: 0;
        }
        .header__notice > p:first-of-type {
          color: #dd514c;
        }
        .header__notice > div:last-child {
          color: #aaa;
          font-size: 12px;
        }
        a {
          color: rgb(46, 176, 144);
        }
      `}</style>
    </header>
  )
}