import React from 'react';

export default function StartNowItem(props) {
  const {
    icon,
    title,
    allPrice,
    href,
    desc,
    footer,
    width
  } = props;

  return (
    <div
      className='start-now__item'
      style={{ width: width }}
    >
      <div
        className='start-now__item__icon'
      >
        <span>{icon}</span>
      </div>
      <div
        className='start-now__item__title'
      >
        {title}
      </div>
      <div
        className='start-now__item__allPrice'
      >
        {allPrice}
      </div>
      <div
        className='start-now__item__desc'
      >
        {desc}
      </div>
      <div
        className='start-now__item__footer'
      >
        {footer}
      </div>

      <style jsx global>{`
        span[data-whb-get] {
          background-color: #e083ba;
          color: #fff;
          text-transform: uppercase;
        }
        span[data-whb-get]:hover {
          background-color: #c34957;
        }
      `}</style>

      <style jsx>{`
        div.start-now__item {
          padding: 24px;
          // border: 1px solid #d1d1d1;
          transition: all 300ms ease;
          box-shadow: 0 0 .5px #d1d1d1;
        }
        @media screen and (max-width: 768px) {
          div.start-now__item {
            width: 100%!important;
          }
           div.start-now__item +  div.start-now__item {
            margin-top: 24px;
           }
        }
        div.start-now__item:hover {
          position: relative;
          z-index: 1;
          // padding-top: 48px;
          // padding-bottom: 48px;
          box-shadow: 0 0 6px #e6e6e6;
          border-color: transparent;
          // flex: none;
        }
        div.start-now__item:hover .start-now__item__icon {
          background-color: #774D22;
        }
        // div.start-now__item + div.start-now__item {
        //   border-left: none;
        // }
        div.start-now__item > * + * {
          margin-top: 24px;
        }
        .start-now__item__icon {
          margin: 0 auto;
          width: 72px;
          height: 72px;
          line-height: 72px;
          flex: none;
          font-size: 28px;
          color: #fff;
          background-color: #C9A96E;
          border-radius: 50%;
          text-align: center;
          box-shadow: 0 0 0 7px #fff inset, 2px 2px 5px #666;
          transition: background-color 300ms ease-in-out;
          cursor: pointer;
        }
        .start-now__item__title {
          min-height: 60px;
          font-size: 20px;
          color: #000;
          text-align: center;
          text-transform: uppercase;
        }
        .start-now__item__allPrice {
          // margin: -24px;
          padding: 24px;
          background-color: #F7F7F7;
          border-top: 1px dotted #E6E6E6;
          text-align: center;
          text-transform: uppercase;
        }
        .start-now__item__allPrice > a {
          display: inline-block;
          padding: 6px 12px;
          background-color: #dfdfdf;
          color: #555;
          text-align: center;
          border-radius: 50px;
          cursor: pointer;
          transition: background-color 300ms ease, color 300ms ease;
        }
        .start-now__item__allPrice > a:hover {
          background-color: #BF9A55;
          color: #FFF;
          text-decoration: none;
        }
        .start-now__item__desc {
          padding: 0 0 24px 0;
          min-height: 92.2px;
          border-bottom: 1px dotted #E6E6E6;
          color: rgb(153, 153, 153);
          font-size: 14px;
          line-height: 1.2;
        }
      `}</style>
    </div>
  )
}