import React from 'react';
import {
  Container
} from "react-bootstrap";
import StartNowItem from '../components/StartNowItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWrench, faCogs } from '@fortawesome/free-solid-svg-icons';

const renderData = [
  {
    icon: (
      <FontAwesomeIcon
        icon={faUser}
      />
    ),
    title: 'Pro Version LIFETIME LICENSE',
    allPrice: (
      <span data-href="https://pay.botchief.com/Agents/Buy?s=V4AyOXL3gp%2fxJsgl9qE44w%3d%3d" data-whb-get>Get it</span>
    ),
    href: '',
    desc: 'The Pro Version allows you to run your bots using multi-threads, this will speed up your tasks well.',
    footer: (
      <div>
        <div
          className='start-now__item__icon'
        >
          <FontAwesomeIcon
            icon={faUser}
          />
        </div>
        <p className="subtitle">PRO VERSION 3-DAY TRIAL, THEN LIFETIME</p>
        <p>
          <span data-href="https://pay.botchief.com/Agents/Buy?s=V4AyOXL3gp%2bpktxF4zzcMg%3d%3d" data-whb-get>Get it</span>
        </p>
        <p style={{ visibility: 'hidden' }}>Or</p>
        <p style={{ visibility: 'hidden' }}>
          <a
            href="https://pay.botchief.com/Agents/Buy?s=V4AyOXL3gp%2bpktxF4zzcMg%3d%3d"
            target='_blank'
            rel="noopener noreferrer"
            style={{ textTransform: 'uppercase' }}
          >
            $7 for 3 days, then
            <span
              className="whb-price whb-red"
              style={{ marginRight: 4, marginLeft: 4 }}
            >
              $237
            </span>
            one time fee
          </a>
        </p>

        <style jsx>{`
          div > p {
            text-align: center;
          }
          div > p.subtitle {
            min-height: 48px;
          }
          div > p:last-child {
            margin-bottom: 0;
          }
          .start-now__item__icon {
            margin: 0 auto 20px;
            width: 72px;
            height: 72px;
            line-height: 72px;
            flex: none;
            font-size: 28px;
            color: #fff;
            background-color: #C9A96E;
            border-radius: 50%;
            text-align: center;
            box-shadow: 0 0 0 7px #fff inset, 2px 2px 5px #666;
            transition: background-color 300ms ease-in-out;
            cursor: pointer;
          }
        `}</style>
      </div>
    )
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faWrench}
      />
    ),
    title: 'Elite Version LIFETIME LICENSE',
    allPrice: (
      <span data-href="https://pay.botchief.com/Agents/Buy?s=V4AyOXL3gp9wKIme2WO4BA%3d%3d" data-whb-get>Get it</span>
    ),
    href: '',
    desc: 'Much more powerful functions will help you to create more powerful software easily.',
    footer: (
      <div>
        <div
          className='start-now__item__icon'
        >
          <FontAwesomeIcon
            icon={faWrench}
          />
        </div>
        <p className="subtitle">ELITE VERSION 3-DAY TRIAL, THEN LIFETIME</p>
        <p>
          <span data-href="https://pay.botchief.com/Agents/Buy?s=V4AyOXL3gp%2fUXE4D36NTTQ%3d%3d" data-whb-get>Get it</span>
        </p>
        <div
          className='start-now__item__icon'
        >
          <FontAwesomeIcon
            icon={faWrench}
          />
        </div>
        <p>ELITE VERSION MONTHLY LICENSE</p>

        <p>
          <span data-href="https://pay.botchief.com/Agents/Buy?s=0guAwQn%2fxbpnyIxVd4BL8g%3d%3d" data-whb-get>Get it</span>
        </p>

        <style jsx>{`
          div > p {
            text-align: center;
          }
          div > p.subtitle {
            min-height: 48px;
          }
          div > p:last-child {
            margin-bottom: 0;
          }
          .start-now__item__icon {
            margin: 0 auto 20px;
            width: 72px;
            height: 72px;
            line-height: 72px;
            flex: none;
            font-size: 28px;
            color: #fff;
            background-color: #C9A96E;
            border-radius: 50%;
            text-align: center;
            box-shadow: 0 0 0 7px #fff inset, 2px 2px 5px #666;
            transition: background-color 300ms ease-in-out;
            cursor: pointer;
          }
        `}</style>
      </div>
    )
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faCogs}
      />
    ),
    title: 'Developer Version LIFETIME LICENSE',
    allPrice: (
      <span data-href="https://pay.botchief.com/Agents/Buy?s=0guAwQn%2fxbq8CGGZt72Ung%3d%3d" data-whb-get>Get it</span>
    ),
    href: '',
    desc: 'Using this version, you can not only use all system bots and create your own bots, but also you can compile your bot to a standalone software to share or sell it.',
    footer: (
      <div>
        <div
          className='start-now__item__icon'
        >
          <FontAwesomeIcon
            icon={faCogs}
          />
        </div>
        <p className="subtitle">DEVELOPER VERSION 3-DAY TRIAL, THEN LIFETIME</p>
        <p>
          <span data-href="https://pay.botchief.com/Agents/Buy?s=0guAwQn%2fxbp68tqrDUyszQ%3d%3d" data-whb-get>Get it</span>
        </p>
        <div
          className='start-now__item__icon'
        >
          <FontAwesomeIcon
            icon={faCogs}
          />
        </div>
        <p>DEVELOPER VERSION MONTHLY LICENSE</p>
        <p>
          <span data-href="https://pay.botchief.com/Agents/Buy?s=0guAwQn%2fxbr9rVz39lbM2g%3d%3d" data-whb-get>Get it</span>

        </p>

        <style jsx>{`
          div > p {
            text-align: center;
          }
          div > p.subtitle {
            min-height: 48px;
          }
          div > p:last-child {
            margin-bottom: 0;
          }
          .start-now__item__icon {
            margin: 0 auto 20px;
            width: 72px;
            height: 72px;
            line-height: 72px;
            flex: none;
            font-size: 28px;
            color: #fff;
            background-color: #C9A96E;
            border-radius: 50%;
            text-align: center;
            box-shadow: 0 0 0 7px #fff inset, 2px 2px 5px #666;
            transition: background-color 300ms ease-in-out;
            cursor: pointer;
          }
        `}</style>


      </div>
    )
  },
];

export default function StartNow() {
  return (
    <>
        <section id="xmas" className="clients section-bg aos-init aos-animate" data-aos="fade-up" data-aos-once="true"
                 style={{textAlign: 'center'}}>
            <div className="give_info" style={{textAlign: 'center', display: 'none'}}>
                <h2>2022 New Year</h2>
                <p className="give_info_p">
                    Merry Christmas! Only today! Only for the <span className="give_info_b">Top 20</span> lucky
                    customers. Hurry up!
                </p>
                <p className="give_info_p">
                    Buy any version today, you will not only enjoy a <span className="give_info_b">40% off</span>, but
                    also get 1 more license for free.
                </p>
            </div>
            <style jsx>{`
                .give_info {
                    position: relative;
                    display: inline-block;
                    background: #77d7fb;
                    background-repeat: no-repeat;
                    border: 4px solid#ff776b;
                    border-radius: 8px;
                    margin: 100px auto 20px;
                }
        
                .give_info h2 {
                    text-align: center;
                    color: #ff776b;
                    margin: 20px;
                }
    
                .give_info::before {
                    position: absolute;
                    top: -4px;
                    bottom: -4px;
                    left: 20px;
                    width: calc(100% - 40px);
                    content: "";
                    border-top: 4px solid #C7DDEF;
                    border-bottom: 4px solid #C7DDEF;
                    z-index: 0;
                }
    
                .give_info::after {
                    position: absolute;
                    top: 20px;
                    right: -4px;
                    left: -4px;
                    height: calc(100% - 40px);
                    content: "";
                    border-right: 4px solid #C7DDEF;
                    border-left: 4px solid #C7DDEF;
                    z-index: 0;
                }
        
                .give_info_p {
                    text-align: left;
                    color: #000000;
                    font-size: 1.6rem;
                    padding: 5px 10px;
                    z-index: 99;
                }
        
                .give_info_b {
                    color: #ff5500;
                    font-weight: 700;
                }
            `}</style>
        </section>
        <section
            id='startNow'
        >
            <Container>
                <h2>START NOW</h2>
                <div>
                    {
                        renderData && renderData.map((item, index) => (
                            <StartNowItem
                                key={index}
                                {...item}
                                width={100 / renderData.length + '%'}
                            />
                        ))
                    }
                </div>
            </Container>

            <style jsx>{`
        section {
          padding: 48px 0;
        }
        section h2 {
          position: relative;
          margin-bottom: 1.5em;
          padding-bottom: 12px;
          text-align: center;
          font-size: 36px;
          text-transform: uppercase;
          color: #BF9A55;
        }
        section h2::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 10%;
          height: 2px;
          background-color: #c6c6c6;
        }
        section h2 + div {
          display: flex;
          justify-content: center;
          align-items: stretch;
          flex-wrap: wrap;
          // box-shadow: 0 0 .5px #d1d1d1;
        }
      `}</style>
            <style jsx global>{`
          .start-now__item__allPrice a:hover .whb-price.whb-red > strong , .start-now__item__footer a:hover .whb-price.whb-red > strong {
            color: #fff;
          }
          .whb-dis-time {
            text-transform: uppercase;
          }
        `}</style>
        </section>
    </>
  )
}