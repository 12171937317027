import React from 'react';

export default function CanYouDoItem(props) {
  const { title, desc, pic } = props;
  return (
    <dl>
      <dt>
        { pic }
      </dt>
      <dd>
        <h3>{ title }</h3>
        <p>{ desc }</p>
      </dd>

      <style jsx>{`
        dl {
          display: flex;
        }
        dl:last-child {
          margin-bottom: 0;
        }
        dl > dt {
          margin-right: 18px;
          width: 72px;
          height: 72px;
          line-height: 72px;
          flex: none;
          font-size: 28px;
          color: #fff;
          background-color: #C9A96E;
          border-radius: 50%;
          text-align: center;
          box-shadow: 0 0 0 7px #fff inset, 2px 2px 5px #666;
          transition: background-color 300ms ease-in-out;
          cursor: pointer;
        }
        dl > dt:hover {
          background-color: #774D22;
        }
        dl > dd {
          margin-bottom: 0;
        }
        dl > dd > h3 {
          text-transform: uppercase;
          font-size: 18px;
        }
        dl > dd > p {
          color: rgb(153, 153, 153);
          line-height: 1.2;
        }
      `}</style>
    </dl>
  )
}