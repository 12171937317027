import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Banner from './containers/Banner';
import Header from './containers/Header';
import CanYouDo from './containers/CanYouDo';
import ChromeEditor from './containers/ChromeEditor';
import BusinessStats from './containers/BusinessStats';
import Anti from './containers/Anti';
import Functions from './containers/Functions';
import StartNow from './containers/StartNow';
import UserSay from './containers/UserSay';
import Footer from './containers/Footer';
import {
  Container
} from "react-bootstrap";


function App() {
  return (
    <>
      <Banner/>
      <Header/>
      <CanYouDo/>
      <ChromeEditor/>
      <BusinessStats/>
      <Anti/>
      <Functions/>
      <StartNow/>
      <UserSay/>
      <section
        id = "partners"
        className = "wa-pre-hide"
      >
        <Container>
          <div
            id = "partners-bom"
            className = ""
          />
        </Container>
      </section>
      <Footer/>

      <style jsx global>{`
        .wa-pre-hide { display:none; }
      `}</style>
    </>
  );
}

export default App;
