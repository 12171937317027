import React from 'react';

export default function UserSayItem(props) {
  const { desc, user } = props;
  const { span, text } = user;

  return (
    <div
      className = 'user-say__item'
    >
      <div>
        <img
          src = {require('../assets/images/user.png')}
          alt = "user.png"
        />
        <div>“{ desc }”</div>
      </div>
      <div>
        <span>{ span }</span>
        { text }
      </div>

      <style jsx>{`
        .user-say__item {
          margin-bottom: 24px;
          padding: 12px;
          border-left: 5px solid #BF9A55;
          box-shadow: 3px 3px 10px #c3c3c3;
        }
        .user-say__item > div:first-child {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #BF9A55;
        }
        .user-say__item > div:first-child > img {
          margin-right: 12px;
          margin-bottom: 6px;
          width: 80px;
          height: 80px;
          flex: none;
        }
        .user-say__item > div:first-child > img + div {
          font-style: italic;
        }
        .user-say__item > div:last-child {
          color: rgb(153, 153, 153);
        }
        .user-say__item > div:last-child > span {
          margin-right: 6px;
          color: #BF9A55;
        }
      `}</style>
    </div>
  )
}