import React, { useState } from 'react';
import Viewer from 'react-viewer';
import {
  Row,
  Col,
  Container
} from "react-bootstrap";
import 'react-viewer/dist/index.css';

const images = [
  {
    src: require('../assets/images/anti__bg__1.png'),
    alt: 'anti__bg__1'
  },
  {
    src: require('../assets/images/anti__bg__2.png'),
    alt: 'anti__bg__2'
  },
  {
    src: require('../assets/images/anti__bg__3.png'),
    alt: 'anti__bg__3'
  },
  {
    src: require('../assets/images/anti__bg__4.png'),
    alt: 'anti__bg__4'
  }
];

export default function Anti() {
  const [ isVisible, setIsVisible ] = useState(false);

  return (
    <section
      className = 'anti__layout'
    >
      <Container>
        <h2 className = 'text-center'>Best Anti-Fingerprint and Anti-tracking Technology</h2>
        <Row>
          <Col
            md = { true }
          >
            <p>Our software approaches browser fingerprinting in a completely indigenous way. Instead of trying to prevent websites from reading your computer’s fingerprint, our software allows reading it but replaces your original fingerprint with a different one. When you use a proxy IP, our software is fully different to other software that only add a proxy to your browser to work, our software will set the timezone, language, DNS and location etc... is matched to your proxy IP, that will make you looks like a real people from the proxy IP. Our software can also generate different device fingerprint and bind different fingerprint with your accounts. Once the account is bind with proxy IP, device fingerprint and other settings, the account will use these settings all the time with all operation. That is why you can use our software to mange and operate many accounts with different proxy safely.</p>
          </Col>
          <Col
            md = { true }>
            <div
              onClick = {() => setIsVisible(true)}
              id = 'antiWrap'
            >
              <img
                src = {require('../assets/images/anti_bg.png')}
                alt = "anti_bg"
              />
               <div/>
            </div>
            <Viewer
              visible = { isVisible }
              onClose={() => setIsVisible(false) }
              images = { images }
              zIndex = { 1030 }
            />
          </Col>
        </Row>
      </Container>

      <style jsx>{`
        .anti__layout {
          padding: 35px 12px;
        }
        // .anti__layout > div:only-child {
        //   margin: 0 auto;
        //   max-width: 1140px;
        // }
        .anti__layout h2 {
          position: relative;
          padding-bottom: 12px;
          text-align: center;
          font-size: 36px;
          text-transform: uppercase;
        }
        .anti__layout h2::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 10%;
          height: 2px;
          background-color: #999;
        }
        .anti__layout #antiWrap > img {
          width: 100%;
          height: 100%;
        }
        .anti__layout #antiWrap > img {
          transition: transform 300ms ease;
          cursor: pointer;
        }
        .anti__layout #antiWrap > img:hover {
          transform: scale(1.1);
        }
        .anti__layout #antiWrap > div:last-child {
          margin: 0 auto;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background: url(${require('../assets/images/hand-pointer.png')}) center/cover;
          animation: pointerMove 300ms infinite alternate;
        }
        @keyframes pointerMove {
          to {
          transform: translateY(0);
        }
        from {
          transform: translateY(-12px);
        }
        
        }

      `}</style>
    </section>
  )
}