import 'core-js';
// import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Array.prototype.includes = function(searchElement,fromIndex){
// 	if (this === null) {
//         throw new TypeError('"this" is null or not defined');
//     }
// 	let that = Object(this),len = that.length >>> 0,param = arguments,index = fromIndex | 0;
	
// 	if(len === 0){return false;}
	
// 	let startIndex = Math.max(index >= 0 ? index : len - Math.abs(index), 0);
	
// 	while(startIndex < len){
// 		if(String(that[startIndex]) === String(param[0]))return true;
// 		startIndex++
// 	}
// 	return false;
// }

// if (!String.prototype.includes) {
//   String.prototype.includes = function(search, start) {
//     'use strict';
//     if (typeof start !== 'number') {
//       start = 0;
//     }

//     if (start + search.length > this.length) {
//       return false;
//     } else {
//       return this.indexOf(search, start) !== -1;
//     }
//   };
// }

ReactDOM.render(<App />, document.getElementById('root'));

// (()=>{
//   window.swid = 130;
//   var nowdt = new Date();
//   var whbpp = nowdt.getFullYear() + '' + nowdt.getMonth() + '' + nowdt.getDate() + '' + nowdt.getHours() + '' + nowdt.getMinutes();
//   document.write(`<link href="http://pay.botchief.com/scripts/dist/common.css?s=${whbpp}" rel="stylesheet" type="text/css">`);
//   document.write(`<script src="http://pay.botchief.com/scripts/dist/common.js?s=${whbpp}" type="text/javascript"><\/script>`);
// })();