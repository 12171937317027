import React from 'react';
import {
  Container,
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';

const renderData = [
  {
    title: 'Navigation',
    children: [
      {
        text: 'Introduce',
        // href: 'javascript: void(0);',
        onClick(){
          window.$('html,body')
            .animate({
              scrollTop: window.$('#canYouDo').offset().top - 66
            },450);
        }
      },
      {
        text: 'Order',
        // href: 'javascript: void(0);',
        onClick(){
          window.$('html,body')
            .animate({
              scrollTop: window.$('#startNow').offset().top - 66
            },450);
        }
      },
      {
        text: 'Videos',
        // href: 'javascript: void(0);',
        onClick(){

        }
      },
      {
        text: 'Functions',
        // href: 'javascript: void(0);',
        onClick(){
          window.$('html,body')
            .animate({
              scrollTop: window.$('#functions').offset().top - 66
            },450);
        }
      }
    ]
  },
  {
    title: 'Get in touch',
    children: [
      {
        text: 'Support Service',
        href: 'https://support.botchief.com'
      },
      {
        text: 'Refund Request',
        href: 'http://support.botchief.com/refund_request'
      },
      // {
      //   text: 'Reviews',
      //   href: 'http://reviews.botchief.com'
      // }
    ]
  }
];

export default function Footer() {
  return (
    <footer>
      <Container>
        <span
          onClick = {() => {
            window.$('html,body')
              .animate({
                scrollTop: 0,
              },450);
          }}
        >
          <FontAwesomeIcon
            icon = { faAngleUp }
          />
        </span>
        <div
          className = 'footer--white'
        >
          {
            renderData && renderData.map((item, index) => (
              <dl
                key = { index }
              >
                <dt>{ item.title }</dt>
                {
                  item.children && item.children.map((item, index) => (
                    <dd
                      key = { index }
                    >
                      <a
                        href = { item.href }
                        onClick = { item.onClick || null }
                        target = '_blank'
                        rel = 'noopener noreferrer'
                      >
                        { item.text }
                      </a>
                    </dd>
                  ))
                }
              </dl>
            ))
          }
        </div>
        <p
          style = {{
            marginBottom: 0,
            paddingBottom: '1em',
            textAlign: 'center'
          }}
        >
          ©2013-{new Date().getFullYear()} <a href = "/" target = '_blank'>BotChief.com</a> All rights reserved.
        </p>
      </Container>

      <style jsx>{`
        footer {
          position: relative;
          padding-top: 60px;
          border-top: 1px solid #eee;
        }
        footer  span {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 40px;
          background-color: #222222;
          border-radius: 4px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 18px;
        }
        div {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        div.footer--white, div.footer--white a {
          color: #999;
        }
        div.footer--white dl {
          padding: 0 10px;
        }
        @media screen and (max-width: 768px) {
          div.footer--white dl {
            width: 100%;
            // text-align: center;
          }
        }
        div.footer--white dt {
        margin-bottom: .6em;
          font-size: 16px;
        }
        div.footer--white dd {
          font-size: 12px;
        }
        div.footer--white a {
          cursor: pointer;
        }
      `}</style>
    </footer>
  )
}