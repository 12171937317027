import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Button
} from "react-bootstrap";

const textList = [
  'Create your customized bots to automate any task with BotChief - no programming knowledge needed!',
  'YOU CAN ALSO SELL THE SOFTWARE THAT CREATED BY BOTCHIEF, MAKE MONEY FAST!',
  'Nothing is impossible with BotChief, the only limitation is your imagination.',
];

export default function Banner(){
  const [ textIndex, setTextIndex ] = useState(0);
  const bannerTextyRef = useRef(null);

  function handleResize() {
    const oParent = bannerTextyRef.current;
    const oActiveKid = oParent.querySelector('.banner__texty--active');
    const H = getComputedStyle(oActiveKid)['height'];
    oParent.style.height = H;
  }
  useEffect(() => {
    handleResize();
    let timer;
    timer = setTimeout(() => {
      if(textIndex +1 >= textList.length){
        setTextIndex(0);
      }else{
        setTextIndex(textIndex +1);
      }
    }, 3000);

    return () => {
      clearInterval(timer);
      timer = null;
    }
  }, [textIndex]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <div
      className = 'banner__layout'
    >
      <div>
        <Container
          className = "banner__texty"
          ref = { bannerTextyRef }
        >
          {
            textList && textList.map((item, index) => (
              <div
                key = { index }
                className = {`${index === textIndex ? 'banner__texty--active' : ''}`}
              >
                { item }
              </div>
            ))
          }
        </Container>
        <Container>
          <ul
            className = 'list-unstyled'
          >
            <li>Automatic</li>
            <li>Multi-Tasks</li>
            <li>Easy to Use</li>
            <li>Very Smart</li>
            <li>Strong Extensibility</li>
          </ul>
          <div>
            <Button
              variant = "outline-flat"
              style = {{ position: 'relative' }}
              onClick = {() => {
                window.$('html,body')
                  .animate({
                    scrollTop: window.$('#root>header').offset().top
                  },450);
              }}
            >
              See More
            </Button>
          </div>
        </Container>
      </div>

      <style jsx global>{`
        .banner__layout {
          position: relative;
          z-index: 1;
          display: table;
          width: 100%;
          height: 100vh;
          background: url('${require('../assets/images/banner.jpg')}') center/cover;
          color: #fff;
        }
        .banner__layout > div:only-child {
          display: table-cell;
          padding: 0 15px;
          vertical-align: middle;
          text-align: center;
        }
        .banner__texty {
          position: relative;
          font-size: 60px;
          transition: height 300ms ease;
        }
        @media screen and (max-width: 768px){
          .banner__texty {
            font-size: 30px;
          }
        }
        .banner__texty > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          text-transform: uppercase;
          opacity: 0;
          z-index: 0;
          transition: transform 300ms ease-in, opacity 300ms ease-in;
          transform-origin: bottom;
        }
        .banner__texty > .banner__texty--active{
          opacity: 1;
          z-index: 1;
        }
        .banner__layout .btn-outline-flat {
          background-color: transparent;
          border: 2px solid #fff;
          color: #fff;
        }
        .banner__layout .btn-outline-flat:hover {
          background-color: #BF9A55;
          border-color: #BF9A55;
        }
        .banner__layout .btn-outline-flat:focus {
          box-shadow: none;
        }
      `}</style>

      <style jsx>{`
        .list-unstyled{
          display: flex;
          justify-content: center;
          align-items: center;
        }
         @media screen and (max-width: 768px){
          .list-unstyled {
            flex-wrap: wrap;
            font-size: 14px;
          }
        }
        .list-unstyled > li {
          color: rgb(153, 153, 153);
        }
        .list-unstyled > li + li {
          position: relative;
          padding-left: 16px;
        }
        .list-unstyled > li + li::before{
          content: '·';
          position: absolute;
          top: 0;
          left: 8px;
          height: 100%;
          transform: scale(2);
          color: #fff;
        }
        button {
          color: red;
        }
      `}</style>
    </div>
  )
}