import React from 'react';
import {
  Container,
} from "react-bootstrap";
import UserSayItem from '../components/UserSayItem';

const renderData = [
  {
    desc: 'Hello and I would like to say ive had the honour to play around with the software and found it very easy to use and with the tutorails you have provided makes life so much easier for anyone to start rather than be left in the dark so cheers for everything you guys have done and continue to do for BotChief.',
    user: {
      span: 'Albert F A Matthews',
      text: 'From United Kingdom'
    }
  },
  {
    desc: 'I made $5000+ by using BotChief about 2 months. I want to say thanks so much to the developer of BotChief! It really makes my life better. Btw I know nothing about programming!',
    user: {
      span: 'Stephen Silvernail',
      text: 'From USA'
    }
  },
  {
    desc: 'I found this software by mistake to be honest, but since I found it I keep using it every day. It can do anything you want on the web. And BotChief is so easy to use compared to it\'s competitors, I love it. PERIOD!',
    user: {
      span: 'Adam',
      text: 'From USA'
    }
  },
];

export default function UserSay(){
  return (
    <section>
      <Container>
        <h2>What Our Users Say...</h2>
        {
          renderData && renderData.map((item, index) => (
            <UserSayItem
              key = { index }
              desc = { item.desc }
              user = { item.user }
            />
          ))
        }
      </Container>

      <style jsx>{`
        section {
          padding: 48px 0;
        }
        section h2 {
          position: relative;
          margin-bottom: 1.5em;
          padding-bottom: 12px;
          text-align: center;
          font-size: 36px;
          text-transform: uppercase;
        }
        section h2::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 10%;
          height: 2px;
          background-color: #c6c6c6;
        }
      `}</style>

      <style jsx global>{`
        section .user-say__item:nth-child(even) {
          direction: ltr;
          transition: padding 300ms ease;
        }
        section .user-say__item:nth-child(even):hover {
          padding: 12px 24px;
        }
        section .user-say__item:nth-child(even) > div:last-child{
          text-align: right;
        }
        section .user-say__item:nth-child(odd) {
          direction: rtl;
          transition: padding 300ms ease;
        }
        section .user-say__item:nth-child(odd):hover {
          padding: 12px 24px;
        }
        section .user-say__item:nth-child(odd) > div:last-child{
          text-align: left;
        }
      `}</style>
    </section>
  )
}