import React from 'react';
import {
  Container
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';

export default function ChromeEditor() {
  return (
    <section>
      <Container>
        <h2>
          <FontAwesomeIcon
            icon = { faLaptop }
            style = {{
              marginRight: 6,
              fontSize: 18,
              verticalAlign: 'unset'
            }}
          />
          <span>Now local Chrome browsers can be used as bot editors</span>
        </h2>
        <p>Creates, modifies, and tests bots in the local Chrome browser. When running bots in the compiler or runner, Chrome will also open to perform operations.</p>
      </Container>

      <style jsx>{`
        section {
          padding: 48px 0;
          background-color: #BF9A55;
          text-align: center;
          color: #fff;
        }
        section h2 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          text-transform: uppercase;
        }
      `}</style>
    </section>
  )
}