import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import CanYouDoItem from '../components/CanYouDoItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faChartBar,
  faCogs,
  faMoneyBill,
  faHandPointUp
} from '@fortawesome/free-solid-svg-icons';
import ImagePreview from '../components/ImagePreivew';

const renderData = [
  {
    title: 'Easy to Use',
    desc: 'Simple clicking mouse and drag-dropping commands will create the software you want, you don\'t need to know any professional programming knowledge.',
    pic: (
      <FontAwesomeIcon
        icon = { faCoffee }
      />
    )
  },
  {
    title: 'Extremely Smart and Powerful',
    desc: 'BotChief can not only create automatic web tools quickly, but it can automatically operate desktop programs. It can even simulate real people to register activity in your web page.',
    pic: (
      <FontAwesomeIcon
        icon = { faChartBar }
      />
    )
  },
  {
    title: 'Compile and Sell',
    desc: 'With a simple mouse click, your idea can become an independent piece of software that you can sell directly to customers and businesses. Build a real program that runs without having to buy anything extra and sell it without paying any royalties. The power is in your hands to convince everyone you hired an expensive team of programmers. You can also use our integrated license and auto-update system for your software, everything is ready for you.',
    pic: (
      <FontAwesomeIcon
        icon = { faCogs }
      />
    )
  },
  {
    title: 'Making Money in Minutes',
    desc: (
      <>
        You can sell or share your bots on our <a href = "http://store.botchief.com" target = "_blank" rel="noopener noreferrer">BotChief Store</a>, and we can help you to promote your products to our huge users, you can begin to make money by just few simple clicks.
      </>
    ),
    pic: (
      <FontAwesomeIcon
        icon = { faMoneyBill }
      />
    )
  },
  {
    title: 'LATEST ANTI-FINGERPRINT TECHNOLOGY TO BYPASS DETECTION',
    desc: 'You can use BotChief with full confidence because it features the latest Anti-fingerprint technology that is able to bypass detection. And yet, the ability to completely replicate human behavior would make even a smart AI like Jarvis, failed to notice it\'s a robot that is visiting the page.',
    pic: (
      <FontAwesomeIcon
        icon = { faHandPointUp }
      />
    )
  }
];

export default function CanYouDo() {
  const [ isVisible, setIsVisible ] = useState(false);

  return (
    <>
      <section
        style = {{ backgroundColor: '#f5f5f5' }}
        id = 'canYouDo'
      >
        <Container
          style = {{ paddingTop: 48, paddingBottom: 48, }}
        >
          <h2 className = 'text-center'>WHAT CAN YOU DO WITH BOTCHIEF?</h2>
          <p>Anything you do online can be automated with BotChief such as account register, content scraper and article submit etc. It will help you to collect and analyze information, synchronize online accounts, upload and download data, finish any other job that you might do in a web browser, and beyond.</p>

          <iframe
            src = "https://www.youtube-nocookie.com/embed/x7aeRg7FHCM"
            allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title = 'Bot Chief'
          />
        </Container>

        <style jsx>{`
          h2 {
            margin-bottom: 10px;
            padding: 16px 0px 16px 35px;
            border-left: 8px solid #D6BE92;
            background: #BF9A55;
            box-shadow: 2px 2px 2px #ccc;
            color: #2d2d2d;
            color: #FFF;
          }
          p {
            text-indent: 2em;
            color: #666;
            line-height: 1.2;
          }
          iframe {
            width: 100%;
            height: 480px;
            vertical-align: middle;
            border: none;
          }
        `}</style>
      </section>

      <section>
        <Container
          style = {{ paddingTop: 48, paddingBottom: 48, }}
        >
          <Row>
            <Col
              md
            >
              {
                renderData && renderData.map((item, index) => (
                  <CanYouDoItem
                    key = { index }
                    title = { item.title }
                    desc = { item.desc }
                    pic = { item.pic }
                  />
                ))
              }
            </Col>
            <Col
              md
            >
              <ImagePreview
                visible = { isVisible }
                onMaskClose = {() => setIsVisible(false)}
                onRenderClick = {() => setIsVisible(true)}
                render = {{
                  type: 'img',
                  src: require('../assets/images/7b.png'),
                  alt: "7b.png",
                  style: { width: '100%' }
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}