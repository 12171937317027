import React from 'react';
import {
  Container,
} from "react-bootstrap";

export default function BusinessStats() {
  return (
    <>
      <section>
        <Container>
          <h2>Proxy and Account Security</h2>
          <p>Bind Proxy and Avoid Account SuspendedThis function lets you bind accounts with appointed proxies, so that later the program will use the same proxy with this account all the time. This will make your account much safer. These sites don't want to see that you are in USA now and then you are in England after 1 min. You can set every account to use a different proxy, you also can set every 2 or X accounts to use the same proxy. The program can also use the conventional proxy rotate method of using a different proxy every time the account logs in. It's very smart, it's all is down to your settings. And TubeAssistPro's operation is same to human's operation, this will avoid your accounts got suspended.</p>
          <ul id="wa-proxies" className="wa-stype" data-kw="proxy"/>
        </Container>

        <style jsx>{`
          section {
            padding: 48px 0;
            text-align: center;
            background-color: #f5f5f5;
          }
          section h2 {
            margin-bottom: 1.6em;
            text-transform: uppercase;
          }
          section p {
            line-height: 1.2;
            color: rgb(153, 153, 153);
          }
        `}</style>
      </section>

      {/*<section>*/}
      {/*  <Container>*/}
      {/*    <h3>Solve Captcha Automatically</h3>*/}
      {/*    <p>Our software will solve captcha automatically for you, you don't need type any captcha manually to make the program to work. We have also integrate some third party captcha solving service that will help you to solve any hard captcha like google captcha fully automatically. </p>*/}
      {/*    <ul id="wa-phones" className="wa-stype" data-kw="phone number"></ul>*/}
      {/*    <ul id="wa-captchas" className="wa-stype" data-kw="captcha"></ul>*/}
      {/*  </Container>*/}

      {/*  <style jsx>{`*/}
      {/*    section {*/}
      {/*      padding: 48px 0;*/}
      {/*      text-align: center;*/}
      {/*    }*/}
      {/*    section h3 {*/}
      {/*      margin-bottom: 1.2em;*/}
      {/*    }*/}
      {/*  `}</style>*/}
      {/*</section>*/}
    </>
  )
}