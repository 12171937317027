import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const oDiv = document.createElement('div');
let oImagePreviewWrap;
if(oDiv.parentNode !== document.body){
  document.body.appendChild(oDiv);
  oImagePreviewWrap = document.createElement('div');
  oImagePreviewWrap.classList.add('image-preview__wrap');
  oDiv.appendChild(oImagePreviewWrap);
}

export default function ImagePreview(props) {
  // console.log(1);
  const { visible, onMaskClose, onRenderClick, render } = props;
  const maskWrapRef = useRef(null);
  const renderImgRef = useRef(null);
  const RenderImg = function(props) {
    const attrs = {};
    for(let i in props){
      if(i !== 'type' && i.indexOf('of') !== 0) attrs[i] = props[i];
    }

    return (
      <props.type
        { ...attrs }
        onClick = { onRenderClick }
        ref = { renderImgRef }
      />
    )
  };
  const MaskImg = function(props) {
    const attrs = {};
    for(let i in props){
      if(i !== 'type' && i.indexOf('on') !== 0 && i !== 'style') attrs[i] = props[i];
    }

    return (
      <props.type
        { ...attrs }
        onClick = {e => e.stopPropagation()}
        className = 'image-preview__img--last'
      />
    )
  };
  useEffect(() => {
    if(visible){
      // console.log(el);
      document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.removeProperty('padding-right');
      document.body.style.removeProperty('overflow');
    }
  }, [visible]);

  return (
    <>
      <RenderImg
        { ...render }
      />

      {
        createPortal(
          <>
            {
              visible && (
                <div
                  className = 'image-preview__item'
                  ref = { maskWrapRef }
                  onClick = {() => onMaskClose()}
                >
                  <MaskImg
                    { ...render }
                  />
                  <span
                    onClick = { onMaskClose }
                  >
                    &times;
                  </span>

                  <style jsx global>{`
                    .image-preview__item {
                      position: fixed;
                      z-index: 999999;
                      top: 0;
                      left: 0;
                      width: 100vw;
                      height: 100vh;
                      background-color: rgba(0, 0, 0, .65);
                    }
                    .image-preview__item > img {
                      position: absolute;
                      margin: auto;
                      width: auto;
                      max-width: 100vw;
                      height: auto;
                      max-height: 100vh;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      transform: scale(0);
                    }
                    .image-preview__item > img.image-preview__img--last {
                      opacity: 1;
                      transform: scale(1);
                    }
                    .image-preview__item > span {
                      position: absolute;
                      top: 0;
                      right: 0;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      font-size: 24px;
                      background-color: rgba(0, 0, 0, .25);
                      color: #fff;
                      cursor: pointer;
                    }
                  `}</style>
                </div>
              )
            }
          </>,
          oImagePreviewWrap
        )
      }
    </>
  );
}